import { PaymentInterval } from '@src/utils/helpers/priceHelpers';
import { BoughtArticles } from '@src/utils/hooks/api/useArticles';

import { MemberDataProps } from 'types/member.types';

export const isMemberAllowedToFreeze = (
    member: MemberDataProps,
    boughtArticles: BoughtArticles[]
) => {
    if (isMcFitMemberWithFreeze(member) || isFreezeIncludedInMembership(member, boughtArticles)) {
        return true;
    }

    return false;
};

const isMcFitMemberWithFreeze = (member: any) => {
    return member?.membership_options_g?.LinkedServices?.includes('freezeAllowed');
};

export const getFreezeArticle = (articles: BoughtArticles[]) => {
    const freezeAddon =
        articles && articles?.find((article) => article.articleDescription.includes('Freeze'));
    return freezeAddon;
};

const isFreezeIncludedInMembership = (
    member: MemberDataProps,
    boughtArticles: BoughtArticles[]
) => {
    const hasFreezeArticle = boughtArticles && getFreezeArticle(boughtArticles) ? true : false;
    return hasFreezeArticle || member.membership_name_g.includes('Unlimited');
};

export const isMemberAllowedToDeleteCurrentFreeze = (member: any) => {
    return member?.freeze?.allowedToDeleteCurrentFreeze;
};

const getMaxFreezeDuration = (member: MemberDataProps) => {
    if (isMcFitMemberWithFreeze(member)) {
        return FreezeDuration.OneMonth;
    } else return FreezeDuration.FourWeeks;
};

export const shouldRenewFreezeContract = (member: MemberDataProps) => {
    const startDate = new Date(member.start_date_g);
    const today = new Date();

    if (startDate === today) {
        return true;
    } else {
        return false;
    }
};

export const getFreezePeriodsNumberPerContract = (member: MemberDataProps) => {
    if (isMcFitMemberWithFreeze(member)) {
        const contractPeriod = member.payment_plan.minimal_contract_period as PaymentInterval;

        switch (contractPeriod) {
            case PaymentInterval.Year:
                return 6;
            case PaymentInterval.FiftyTwoWeeks:
                return 6;
            case PaymentInterval.HalfYear:
                return 3;
            case PaymentInterval.ThreeMonths:
                return 3;
            case PaymentInterval.OneMonth:
                return 3;
            default:
                return 0;
        }
    } else {
        return 2;
    }
};

enum FreezeDuration {
    OneMonth = 'oneMonth', // McFit
    FourWeeks = '4weeks' // Basic-Fit
}
