import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Text } from '@basic-fit/design-system';
import { Modal } from '@src/components/Modals/Modal';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    inDebt: boolean;
};

export const FreezeModal = ({ isOpen, onClose, inDebt }: ModalProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <Heading size={1} className="mb-xs" data-cy="debits-modal-title">
                {t('freeze.modal.not.available')}
            </Heading>

            <Text className="mb-xs first-letter:uppercase">{t('freeze.modal.debt.message')}</Text>

            <Button
                className="mt-4"
                fullwidth
                onClick={() => navigate('/payments')}
                data-cy="freeze-modal-delete-button"
            >
                {t('freeze.modal.debt.pay.button')}
            </Button>
            <Button
                className="mt-4"
                theme="light"
                fullwidth
                data-cy="freeze-modal-cancel-button"
                onClick={() => onClose()}
            >
                {t('freeze.modal.debt.back.button')}
            </Button>
        </Modal>
    );
};
